<template>
    <div class="head-skeleton-loader" :class="parent">
        <div class="skeleton-content">
            <div class="skeleton-avatar">
                <v-skeleton-loader type="image" class="skeleton-user-image"></v-skeleton-loader>
            </div>

            <div class="skeleton-text">
                <v-skeleton-loader type="image" class="line-215"></v-skeleton-loader>
                <v-skeleton-loader type="image" class="line-230"></v-skeleton-loader>
                <v-skeleton-loader type="image" class="line-330"></v-skeleton-loader>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "headSkeletonLoader",
    props: ['parent']
}
</script>

<style lang="scss" scoped>
// Variables
$primary-bg: #10182B;

// Mixins
@mixin flex-center {
    display: flex;
    align-items: center;
}

.head-skeleton-loader {
    .skeleton-content {
        @include flex-center;
        align-items: flex-start;
        position: relative;
        z-index: 1;
        max-width: 1290px;
        
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            padding: 15px 0;
        }
    }

    .skeleton-avatar {
        margin-right: 15px;
        position: relative;
        
        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 8px;
        }
        
        .skeleton-user-image {
            width: 240px;
            height: 270px;
            border-radius: 8px;
            background-color: $primary-bg;
            
            ::v-deep .v-skeleton-loader__image {
                height: 100%;
                border-radius: 8px;
            }
        }
    }

    .skeleton-text {
        flex: 1;
        align-self: center;
        
        @media (max-width: 767px) {
            width: 100%;
            margin-top: 0;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        
        ::v-deep .v-skeleton-loader__image {
            height: 25px;
            margin-bottom: 5px;
            
            @media (max-width: 767px) {
                margin: 0 auto 5px;
            }
        }
        
        .line-215 {
            width: 215px;
            @media (max-width: 480px) {
                width: 200px;
            }
        }
        
        .line-230 {
            width: 230px;
            @media (max-width: 480px) {
                margin: 0 auto;
            }
        }
        
        .line-330 {
            width: 260px;
            @media (max-width: 480px) {
                width: 100%;
                max-width: 330px;
            }
        }
    }
}
</style>