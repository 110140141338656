export function extractColorsFromImage(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      canvas.width = img.width;
      canvas.height = img.height;
      
      ctx.drawImage(img, 0, 0);
      
      // Sample from upper and lower thirds of the image
      const topColor = getAverageColor(ctx, 0, 0, img.width, img.height * 0.33);
      const bottomColor = getAverageColor(ctx, 0, img.height * 0.66, img.width, img.height * 0.34);
      
      resolve({
        topColor: `rgb(${topColor.join(',')})`,
        bottomColor: `rgb(${bottomColor.join(',')})`
      });
    };

    img.onerror = reject;
    img.src = imageUrl;
  });
}

function getAverageColor(ctx, x, y, width, height) {
  const imageData = ctx.getImageData(x, y, width, height);
  const data = imageData.data;
  
  let r = 0, g = 0, b = 0;
  let count = 0;
  
  // Sample every 10th pixel for performance
  for (let i = 0; i < data.length; i += 40) {
    r += data[i];
    g += data[i + 1];
    b += data[i + 2];
    count++;
  }
  
  // Calculate average and ensure colors aren't too bright
  return [
    Math.min(Math.floor(r / count), 180),
    Math.min(Math.floor(g / count), 180),
    Math.min(Math.floor(b / count), 180)
  ];
}